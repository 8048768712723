
.main-wrapper {
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}
.train-content {
    flex: 1;
    height: 1%;
    border-top: 1px solid #F6F5F8;
    padding: 25px 40px;
    .train-item {
        display: flex;
        justify-content: space-between;
    }
    .attr-item {
        .right-item-sub {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .project-duration-attr {
                .duration-title {
                    margin-bottom: 14px;
                    em {
                        color: #FF0000;
                    }
                    span {
                        color: #333333;
                        margin-left: 8px;
                    }
                }
                .duration-value-box {
                    ::v-deep .el-input {
                        width: 150px;
                    }
                    /*span {*/
                        /*margin-left: 8px;*/
                    /*}*/
                }
            }
        }
        width: 360px;
        font-size: 14px;
        margin-bottom: 40px;
        .attr-item-title {
            margin-bottom: 14px;
            em {
                color: #FF0000;
            }
            span {
                color: #333333;
                margin-left: 8px;
            }
            .title {
                i {
                    color: #409EFF;
                    margin-left: 10px;
                    cursor: pointer;
                }
            }
        }
        .attr-item-value {
            ::v-deep .el-cascader {
                width: 100%;
            }
            ::v-deep .el-input__inner,
            ::v-deep .el-textarea__inner {
                background-color: #F5F7FA;
                resize: none;
                &::placeholder {
                    color: #999999;
                }
            }
            .platform-select {
                width: 100%;
            }
        }
        .duration-value-box {
            display: flex;
            align-items: center;
            height: 40px;
        }
    }
}
.pannel-project-attrs-normal {
    width: 100%;
    margin-bottom: 40px;
    .attr-flex-header {
        height: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 12px;
        &.space-between-position {
            justify-content: space-between;
        }
    }
    .attr-flex-content {
        ::v-deep .evaluete-header {
            background-color: #F5F7FA;
        }
        ::v-deep .el-textarea__inner {
            background-color: #F5F7FA;
        }
        .upload-sucai {
            .sucai-upload-area {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .el-upload__tip {
                    margin-left: 20px;
                    font-size: 14px;
                    color: #999999;
                }
            }
            ::v-deep .el-button {
                background-color: #DCECFE;
                border: 1px solid #DCECFE;
                span {
                    color: #409EFF;
                    i.iconfont {
                        font-size: 14px;
                    }
                }
            }
            ::v-deep .el-upload-list {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: center;
            }
            ::v-deep .el-upload-list__item {
                width: 228px;
                margin-right: 21px;
            }
            ::v-deep .el-upload-list__item-name {
                .el-icon-document {
                    color: #409eff;
                    font-size: 18px;
                }
            }
        }
    }
    .form-submit-buttons {
        margin-top: 47px;
        display: flex;
        align-items: center;
        justify-content: center;
        ::v-deep .el-button--primary {
            color: #FFF;
            background-color: #1D35E3;
            border-color: #1D35E3;
        }
    }
    .attr-flex-action {
        ::v-deep .el-button--primary {
            color: #FFF;
            background-color: #1D35E3;
            border-color: #1D35E3;
        }
    }
    .attr-flex-title {
        em {
            color: #FF0000;
        }
        span {
            margin-left: 8px;
        }
    }
}

